import { createAction, props } from '@ngrx/store';
import { User } from 'firebase';
import { UserInterface, PhoneInterface, CredentialsInterface, AuthHeaderDataInterface } from '@bts/objects';

export const isAuthenticated = createAction('[AUTH] Is user authenticated?');
export const authenticated = createAction('[AUTH] User is Authenticated', props<{ payload: User }>());
export const notAuthenticated = createAction('[AUTH] User is not authenticated');
export const signIn = createAction('[AUTH] Sign In', props<{ payload: CredentialsInterface }>());
export const signInSuccess = createAction('[AUTH] Sign In success');
export const fetchUserSuccess = createAction('[AUTH] Fetch user success', props<{ payload: UserInterface }>());
export const signUp = createAction('[AUTH] Sign Up', props<{ payload: CredentialsInterface }>());
export const storeUser = createAction('[AUTH] Store user', props<{ payload: UserInterface }>());
export const storeUserSuccess = createAction('[AUTH] Store user success', props<{ payload: UserInterface }>());
export const setIsNewUser = createAction('[AUTH] Set is new user');
export const setIsNotNewUser = createAction('[AUTH] Set is not new user');
export const signOut = createAction('[AUTH] Sign out');
export const signOutSuccess = createAction('[AUTH] Sign out success');
export const provideMobileNumber = createAction('[AUTH] Provide mobile number', props<{ payload: { phone: PhoneInterface, verifier: firebase.auth.RecaptchaVerifier }}>());
export const setPhoneConfirmationResult = createAction('[AUTH] Set mobile confirmation result', props<{ payload: firebase.auth.ConfirmationResult }>());
export const confirmMobileNumber = createAction('[AUTH] Confirm mobile number', props<{ payload: string }>());
export const sendForgottenPassword = createAction('[AUTH] Send forgotten password link', props<{ payload: string }>());
export const resetPassword = createAction('[AUTH] Reset password', props<{ payload: { token: string, password: string }}>());
export const setHeaderData = createAction('[AUTH] Set auth header data', props<{ payload: AuthHeaderDataInterface }>());
export const setCloseAuthUrlStatus = createAction('[AUTH], Setu close auth url status', props<{ payload: boolean }>());
export const updateMobileNumber = createAction('[AUTH] Update user mobile number', props<{ payload: { phone: PhoneInterface, verifier: firebase.auth.RecaptchaVerifier }}>());
export const changeEmail = createAction('[AUTH] Change email', props<{ payload: string }>());
export const reauthenticate = createAction('[AUTH] Reauthenticate', props<{ payload: { oldPassword: string, newPassword: string, email: string }}>());
export const changePassword = createAction('[AUTH] Change password', props<{ payload: string }>());
export const confirmEmail = createAction('[AUTH] Confirm email', props<{ payload: string }>());
export const resendConfirmationEmail = createAction('[AUTH] Resend confirmation email');