import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '@store/app-store.reducer';
import { LanguageService } from '@services/language.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { MessageInterface, MessageTypes } from '@bts/objects'
import { removeMessage } from '@store/ui/ui.actions';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',

})
export class MessagesComponent implements OnInit, OnDestroy {

    messages: MessageInterface[] = [];
    uuids: any = {};
    private toastVisibleTime = 8000;

    constructor(
        private languageService: LanguageService,
        private store: Store<fromRoot.State>,
        private afs: AngularFirestore
    ) {}

    ngOnDestroy() {}

    ngOnInit() {
        this.store.select(fromRoot.getMessages)
        .subscribe((data: MessageInterface[]) => {            
			this.messages = [];
			console.log(data);
            data.forEach((message, key) => {
                if (!this.uuids[message.uuid]) {
                    this.uuids[message.uuid] = setTimeout(() => {                        
                        this.remove(message);
                    }, this.toastVisibleTime);
                }
               
                if (message.messageType === MessageTypes.ERROR) {
                    this.processErrorMessage(message);
                } else if (message.messageType === MessageTypes.INFO) {
                    this.processInfoMessage(message);
                }
            });
        });
    }

    remove(message: MessageInterface): void {
        clearTimeout(this.uuids[message.uuid]);
        delete this.uuids[message.uuid];
        this.store.dispatch(removeMessage({ payload: message.uuid }));
    }

    getClass(message: MessageInterface): string {
        switch (message.messageType) {
            case MessageTypes.ERROR:
                return 'toast-error';

            default:
                return 'toast-primary';
        }
    }

    private getMessage(code: string, readable: string): string {        
        const m = this.languageService.getInstantTranslation(code);
        
        if (!m || m === code) {        
            this.afs.collection('translations').doc(code).set({
                readable: readable
            });
        
            return readable;
        }
        
        return m;
    }    

    private processErrorMessage(message: MessageInterface): void {
        const code = this.prepareCode(message.code);
        const readable = this.getMessage(code, message.readable);

        this.messages.push({
            code: code,
            messageType: MessageTypes.ERROR,
            readable: readable,
            uuid: message.uuid
        });
    }

    private processInfoMessage(message: any): void {
        const code = this.prepareCode(message.code);
        const readable = this.getMessage(code, message.readable);

        this.messages.push({
            code: code,
			messageType: MessageTypes.INFO,
            readable: readable,
            uuid: message.uuid
        });
    }

    private prepareCode(code: string): string {
        code = code.split('/').join('_');
        code = code.split('-').join('_');
        code = code.toUpperCase();

        return code;
    }
}
