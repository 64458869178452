import { Injectable, } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE, LANGUAGES } from '@configs/app.constants';
import { Store } from '@ngrx/store';
import * as fromRoot from '@store/app-store.reducer';
import { UserInterface } from '@bts/objects'
import { StorageService } from './storage.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { User } from '@objects/user.object';

@Injectable()
export class LanguageService {

    private lang: string;
    private user: User;

    constructor(
        private translate: TranslateService,
        private storage: StorageService,
        private afs: AngularFirestore,
        private store: Store<fromRoot.State>
    ) {        
        translate.addLangs(this.getAllLanguages());
        translate.setDefaultLang(DEFAULT_LANGUAGE);

        this.store.select(fromRoot.isAuthLoaded).subscribe((isLoaded: boolean) => {
            if (isLoaded) {
                this.store.select(fromRoot.getUser).subscribe((user: UserInterface) => {
                    this.user = user ? new User(user) : null;
                    if (user) {
                        this.firstLanguageCheck();
                    }
                    else {
                        this.store.select(fromRoot.getLanguage).subscribe((lang: string) => {
                            this.lang = lang;
                            this.firstLanguageCheck();
                        })
                    }
                })
            }
        });
    }

    getAllLanguages() {
        const langs = []
        Object.keys(LANGUAGES).forEach(key => {            
            langs.push(LANGUAGES[key]);
        });

        return langs;
    }

    firstLanguageCheck() {        

        if (this.user && this.user.language) {
            this.setLanguage(this.user.language);
        } else if (this.lang) {
            this.setLanguage(this.lang);
        } else {
            this.setLanguage(DEFAULT_LANGUAGE);
        }
    }

    setLanguage(language: string, reload?: boolean) {
        let lang: string;
        switch (language) {
            case 'sl':
                lang = 'sl';
                break;

            default:
                lang = DEFAULT_LANGUAGE;
                break;
        }

        if (this.translate.currentLang === lang) {
            return;
        }

        this.translate.use(lang);
        this.lang = lang;
        this.storage.set('lang', lang);
        this.setMomentLanguage(lang);
        if (this.user && this.user.language !== lang) {
            this.afs.collection('users').doc(this.user.uid).update({
                language: lang
            })
            .then(res => {
                if (reload) {
                    this.refresh();
                }
            });
        } else {
            if (reload) {
                this.refresh();
            }
        }
    }

    getInstantTranslation(key: string): string {
        const translation = this.translate.instant(key);

        return translation ? translation : null;
    }

    getTranslations(key: string): Promise<string> {
        return this.translate.get(key).toPromise();
    }

    getShortLanguage(): string {
        return this.lang;
    }

    getLanguage() {
        let language = {
            full: 'English',
            flag: 'gb',
            short: 'en'
        };

        switch (this.translate.currentLang) {
            case 'sl':
                language = {
                    full: 'Slovenščina',
                    flag: 'si',
                    short: 'sl'
                };
                break;

            default:
                break;
        }

        return language;
    }

    private refresh() {
        location.reload();
    }

    private setMomentLanguage(lang) {
        let locale: string;

        switch (lang) {
            case 'sl':
                locale = 'sl';
                break;
            default:
                locale = 'en-gb';
                break;
        }

        moment.locale(locale);
        moment.updateLocale(locale, null);
    }
}
