export const SL = {
    AF: "Afganistan",
    AX: "Ålandski otoki",
    AL: "Albanija",
    DZ: "Alžirija",
    AS: "Ameriška Samoa",
    VI: "Ameriški Deviški otoki",
    AD: "Andora",
    AO: "Angola",
    AI: "Angvila",
    AQ: "Antarktika",
    AG: "Antigva in Barbuda",
    AR: "Argentina",
    AM: "Armenija",
    AW: "Aruba",
    AU: "Avstralija",
    AT: "Avstrija",
    AZ: "Azerbajdžan",
    BS: "Bahami",
    BH: "Bahrajn",
    BD: "Bangladeš",
    BB: "Barbados",
    BE: "Belgija",
    BZ: "Belize",
    BY: "Belorusija",
    BJ: "Benin",
    BM: "Bermudi",
    BW: "Bocvana",
    BG: "Bolgarija",
    BO: "Bolivija",
    BA: "Bosna in Hercegovina",
    CX: "Božični otok",
    BR: "Brazilija",
    VG: "Britanski Deviški otoki",
    IO: "Britansko ozemlje v Indijskem oceanu",
    BN: "Brunej",
    BF: "Burkina Faso",
    BI: "Burundi",
    BT: "Butan",
    CF: "Centralnoafriška republika",
    EA: "Ceuta in Melilla",
    CY: "Ciper",
    CK: "Cookovi otoki",
    CW: "Curaçao",
    TD: "Čad",
    CZ: "Češka",
    CL: "Čile",
    ME: "Črna gora",
    DK: "Danska",
    CD: "Demokratična republika Kongo",
    DG: "Diego Garcia",
    DM: "Dominika",
    DO: "Dominikanska republika",
    DJ: "Džibuti",
    EG: "Egipt",
    EC: "Ekvador",
    GQ: "Ekvatorialna Gvineja",
    ER: "Eritreja",
    EE: "Estonija",
    ET: "Etiopija",
    EZ: "Eurozone",
    FK: "Falklandski otoki",
    FO: "Ferski otoki",
    FJ: "Fidži",
    PH: "Filipini",
    FI: "Finska",
    FR: "Francija",
    GF: "Francoska Gvajana",
    PF: "Francoska Polinezija",
    TF: "Francosko južno ozemlje",
    GA: "Gabon",
    GM: "Gambija",
    GH: "Gana",
    GI: "Gibraltar",
    GR: "Grčija",
    GD: "Grenada",
    GL: "Grenlandija",
    GE: "Gruzija",
    GU: "Guam",
    GG: "Guernsey",
    GP: "Gvadalupe",
    GY: "Gvajana",
    GT: "Gvatemala",
    GN: "Gvineja",
    GW: "Gvineja Bissau",
    HT: "Haiti",
    HN: "Honduras",
    HR: "Hrvaška",
    IN: "Indija",
    ID: "Indonezija",
    IQ: "Irak",
    IR: "Iran",
    IE: "Irska",
    IS: "Islandija",
    IT: "Italija",
    IL: "Izrael",
    JM: "Jamajka",
    JP: "Japonska",
    YE: "Jemen",
    JE: "Jersey",
    JO: "Jordanija",
    GS: "Južna Georgia in Južni Sandwichevi otoki",
    KR: "Južna Koreja",
    SS: "Južni Sudan",
    ZA: "Južnoafriška republika",
    KY: "Kajmanski otoki",
    KH: "Kambodža",
    CM: "Kamerun",
    CA: "Kanada",
    IC: "Kanarski otoki",
    QA: "Katar",
    KZ: "Kazahstan",
    KE: "Kenija",
    KG: "Kirgizistan",
    KI: "Kiribati",
    CN: "Kitajska",
    CC: "Kokosovi otoki",
    CO: "Kolumbija",
    KM: "Komori",
    CG: "Kongo - Brazzaville",
    XK: "Kosovo",
    CR: "Kostarika",
    CU: "Kuba",
    KW: "Kuvajt",
    LA: "Laos",
    LV: "Latvija",
    LS: "Lesoto",
    LB: "Libanon",
    LR: "Liberija",
    LY: "Libija",
    LI: "Lihtenštajn",
    LT: "Litva",
    LU: "Luksemburg",
    MG: "Madagaskar",
    HU: "Madžarska",
    MK: "Makedonija",
    MW: "Malavi",
    MV: "Maldivi",
    MY: "Malezija",
    ML: "Mali",
    MT: "Malta",
    MA: "Maroko",
    MH: "Marshallovi otoki",
    MQ: "Martinik",
    MU: "Mauritius",
    MR: "Mavretanija",
    YT: "Mayotte",
    MX: "Mehika",
    FM: "Mikronezija",
    MM: "Mjanmar (Burma)",
    MD: "Moldavija",
    MC: "Monako",
    MN: "Mongolija",
    MS: "Montserrat",
    MZ: "Mozambik",
    NA: "Namibija",
    NR: "Nauru",
    DE: "Nemčija",
    NP: "Nepal",
    NE: "Niger",
    NG: "Nigerija",
    NI: "Nikaragva",
    NU: "Niue",
    NL: "Nizozemska",
    BQ: "Nizozemski Karibi",
    NF: "Norfolški otok",
    NO: "Norveška",
    NC: "Nova Kaledonija",
    NZ: "Nova Zelandija",
    OM: "Oman",
    AC: "Otok Ascension",
    IM: "Otok Man",
    TC: "Otoki Turks in Caicos",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinsko ozemlje",
    PA: "Panama",
    PG: "Papua Nova Gvineja",
    PY: "Paragvaj",
    PE: "Peru",
    PN: "Pitcairn",
    PL: "Poljska",
    PR: "Portoriko",
    PT: "Portugalska",
    HK: "Posebno administrativno območje LR Kitajske Hongkong",
    MO: "Posebno administrativno območje LR Kitajske Macao",
    RE: "Reunion",
    RO: "Romunija",
    RW: "Ruanda",
    RU: "Rusija",
    BL: "Saint Barthélemy",
    KN: "Saint Kitts in Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin",
    PM: "Saint Pierre in Miquelon",
    VC: "Saint Vincent in Grenadine",
    SB: "Salomonovi otoki",
    SV: "Salvador",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome in Principe",
    SA: "Saudova Arabija",
    SC: "Sejšeli",
    SN: "Senegal",
    KP: "Severna Koreja",
    MP: "Severni Marianski otoki",
    SL: "Sierra Leone",
    SG: "Singapur",
    SX: "Sint Maarten",
    SY: "Sirija",
    CI: "Slonokoščena obala",
    SK: "Slovaška",
    SI: "Slovenija",
    SO: "Somalija",
    RS: "Srbija",
    UM: "Stranski zunanji otoki Združenih držav",
    SD: "Sudan",
    SR: "Surinam",
    SJ: "Svalbard in Jan Mayen",
    SZ: "Svazi",
    SH: "Sveta Helena",
    ES: "Španija",
    LK: "Šrilanka",
    SE: "Švedska",
    CH: "Švica",
    TJ: "Tadžikistan",
    TH: "Tajska",
    TW: "Tajvan",
    TZ: "Tanzanija",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad in Tobago",
    TA: "Tristan da Cunha",
    TN: "Tunizija",
    TR: "Turčija",
    TM: "Turkmenistan",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukrajina",
    UY: "Urugvaj",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VA: "Vatikan",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis in Futuna",
    EH: "Zahodna Sahara",
    ZM: "Zambija",
    US: "Združene države Amerike",
    AE: "Združeni arabski emirati",
    UN: "Združeni narodi",
    GB: "Združeno kraljestvo",
    CV: "Zelenortski otoki",
    ZW: "Zimbabve"
};