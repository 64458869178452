import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, of, forkJoin } from 'rxjs';
import { map, switchMap, take, catchError, withLatestFrom, mergeMap, concatMap } from 'rxjs/operators';
import { User } from 'firebase';
import * as firebase from 'firebase/app';
import { Store, select } from '@ngrx/store';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as googleLibphonenumber from 'google-libphonenumber';
import { auth } from 'firebase/app';
import { setLoadingActionName } from '@store/ui/ui.actions';
import { MessageTypes, CredentialsInterface, PhoneInterface, UserInterface } from '@bts/objects'
import { signIn, signInSuccess, isAuthenticated, notAuthenticated, authenticated, fetchUserSuccess, signUp,
	setIsNewUser, storeUser, storeUserSuccess, signOut, signOutSuccess, provideMobileNumber, setPhoneConfirmationResult,
	confirmMobileNumber, setIsNotNewUser, sendForgottenPassword, resetPassword, 
	updateMobileNumber, changeEmail, changePassword, reauthenticate, confirmEmail, resendConfirmationEmail } from "./auth.actions";
import * as fromRoot from '@store/app-store.reducer';
import { PROVIDE_PHONE_NUMBER_URL, FUNCTIONS_URL, 
	FUNCTIONS_SEND_PASSWORD_RESET_URL, FUNCTIONS_RESET_PASSWORD_URL,
	SIGN_IN_URL, FUNCTIONS_CHANGE_EMAIL_URL, CHANGED_EMAIL_URL, CONFIRM_EMAIL_URL,
	FUNCTIONS_CONFIRM_EMAIL_URL, FUNCTIONS_RESEND_CONFIRMATION_EMAIL_URL, CONFIRM_PHONE_NUMBER_URL } from '@configs/app.constants';
import { HttpClient } from '@angular/common/http';
import { Error } from '@configs/error';
import { StorageService } from '@services/storage.service';
import { BaseEffects } from '@store/base.effects';

@Injectable()
export class AuthEffects extends BaseEffects {

	constructor(
		private actions$: Actions,
		private afa: AngularFireAuth,
		private afs: AngularFirestore,
		private store: Store<fromRoot.State>,
		private router: Router,
		private http: HttpClient,
		private storage: StorageService
	) {
		super();
	}

	isAuthenticated$ = createEffect(
		() => this.actions$.pipe(
			ofType(isAuthenticated),
			switchMap(() => this.afa.authState
				.pipe(
					map((user: User) => {
						console.log('authenticated');
						if (user) {
							return authenticated({ payload: user });
						}
						else {
							return notAuthenticated();
						}
					})
				)
			)
		)
	);

	fetchUserData$ = createEffect(
		() => this.actions$.pipe(
			ofType(authenticated),
			map(action => action.payload),
			switchMap((userData: User) => this.afs.collection<UserInterface>('users').doc(userData.uid).valueChanges()
				.pipe(
					map((user: UserInterface) => {
						return fetchUserSuccess({ payload: user });
					}),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR))
				)
			)
		)
	);

	fetchUserSuccss = createEffect(
		() => this.actions$.pipe(
			ofType(fetchUserSuccess),
			mergeMap((action) =>
				of(action.payload).pipe(
					withLatestFrom(
						this.store.pipe(select(fromRoot.getIsUserNew)),
						this.store.pipe(select(fromRoot.getNextUrl)),
						this.store.pipe(select(fromRoot.getCloseAuthUrlStatus))
					)
				),
				(_, latestStoreData) => latestStoreData
			),
			map(([_, isUserNew, nextUrl, closeStatus]) => {
				if(isUserNew) {
					this.router.navigateByUrl(PROVIDE_PHONE_NUMBER_URL);
				}
				else if (closeStatus) {
					this.router.navigateByUrl(nextUrl);
				}
			})
		),
		{ dispatch: false }
	);

	signIn$ = createEffect(
		() => this.actions$.pipe(
			ofType(signIn),
			map(action => action.payload),
			switchMap((data: CredentialsInterface) => from(this.afa.signInWithEmailAndPassword(data.email, data.password))
				.pipe(
					map((_: firebase.auth.UserCredential) => signInSuccess()),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			)
		)
	);

	signUp$ = createEffect(
		() => this.actions$.pipe(
			ofType(signUp),
			map(action => action.payload),
			switchMap((data: CredentialsInterface) => from(this.afa.createUserWithEmailAndPassword(data.email, data.password))
				.pipe(
					map((state: firebase.auth.UserCredential) => {
						const user: UserInterface = this.processPasswordData(state);
						return storeUser({ payload: user });
					}),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			)
		)
	);

	storeUser$ = createEffect(
		() => this.actions$.pipe(
			ofType(storeUser),
			map(action => action.payload),
			switchMap((user: UserInterface) => from(this.afs.collection('users').doc(user.uid).set(user, { merge: true }))
				.pipe(
					map(() => {
						console.log('store user');
						return storeUserSuccess({ payload: user });
					}),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)   
			)
		)
	);

	signOut$ = createEffect(
		() => this.actions$.pipe(
			ofType(signOut),
			switchMap(() => from(this.afa.signOut())
				.pipe(
					map(() => {
						this.router.navigate(['/']);
						return signOutSuccess();
					}),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			)
		)
	);

	provideMobileNumer$ = createEffect(
		() => this.actions$.pipe(
			ofType(provideMobileNumber),
			map(action => action.payload),
			switchMap((data: { phone: PhoneInterface, verifier: firebase.auth.RecaptchaVerifier }) => from(this.afa.currentUser)
				.pipe(
					switchMap((currentUser: User) => currentUser.linkWithPhoneNumber('+' + data.phone.code + data.phone.number, data.verifier)),
					map((result: firebase.auth.ConfirmationResult) => {
						data.verifier.clear();
						this.router.navigateByUrl(CONFIRM_PHONE_NUMBER_URL);
						return setPhoneConfirmationResult({ payload: result });
					}),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			)
		)
	);

	updateMobileNumber$ = createEffect(
		() => this.actions$.pipe(
			ofType(updateMobileNumber),
			mergeMap((action) =>
				of(action).pipe(
					withLatestFrom(
						this.store.pipe(select(fromRoot.getUser))
					)
				),
				(_, latestStoreData) => latestStoreData
			),
			switchMap(([data, user]) => forkJoin([
				from(firebase.auth().currentUser.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID)),
				from(this.afs.collection('users').doc(user.uid).set({ phone: null, phone_verified: false }, { merge: true }))
			]).pipe(
				map(() => provideMobileNumber(data)),
				catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
				take(1)
			)
		))
	);

	confirmMobileNumber$ = createEffect(
		() => this.actions$.pipe(
			ofType(confirmMobileNumber),
			mergeMap((action) =>
				of(action.payload).pipe(
					withLatestFrom(
						this.store.pipe(select(fromRoot.getConfirmation)),
						this.store.pipe(select(fromRoot.getUser)),
						this.store.pipe(select(fromRoot.getNextUrl))
					)
				),
				(_, latestStoreData) => latestStoreData
			),
			switchMap(([code, confirmation, user, nextUrl]) => {
				return from(confirmation.confirm(code))
				.pipe(
					switchMap((_: firebase.auth.UserCredential) => this.afa.currentUser),
					map((currentUser: User) => {
						this.store.dispatch(setIsNotNewUser());
                    
						// save user new phone number
						const util = googleLibphonenumber.PhoneNumberUtil.getInstance();
						const number = util.parse(currentUser.phoneNumber, '');
						user.phone = {
							code: number.getCountryCode(),
							number: number.getNationalNumber()
						};
						user.phone_verified = true;
						
						this.router.navigateByUrl(nextUrl);
						return storeUser({ payload: user });
					}),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			})
		)
	);

	sendForgottenPassword$ = createEffect(
		() => this.actions$.pipe(
			ofType(sendForgottenPassword),
			map(action => action.payload),
			switchMap((email: string) => this.http.post(FUNCTIONS_URL + FUNCTIONS_SEND_PASSWORD_RESET_URL, { email: email })
				.pipe(
					map((response: any) => {
						if (response.success) {
							return this.showMessageAction('Email with password reset link was sent', 'auth/password-reset-sent', MessageTypes.INFO);
						}

						throw new Error(response.content.code, response.content.message);
					}),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			)
		)
	);

	resetPassword$ = createEffect(
		() => this.actions$.pipe(
			ofType(resetPassword),
			map(action => action.payload),
			switchMap((data: { password: string, token: string }) => this.http.post(FUNCTIONS_URL + FUNCTIONS_RESET_PASSWORD_URL, data)
				.pipe(
					map((response: any) => {
						if (response.success) {
							return this.router.navigateByUrl(SIGN_IN_URL);
						}

						throw new Error(response.content.code, response.content.message);
					}),
					map(() => this.showMessageAction('Your password was reset', 'auth/reset-password', MessageTypes.INFO)),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			)
		)
	);

	changeEmail$ = createEffect(
		() => this.actions$.pipe(
			ofType(changeEmail),
			map(action => action.payload),
			switchMap((email: string) => this.http.post(FUNCTIONS_URL + FUNCTIONS_CHANGE_EMAIL_URL, {email: email})
				.pipe(
					map((response: any) => {
						if (!response.success) {
							throw new Error(response.content.code, response.content.message);
						}
					}),
					catchError(error => this.showMessageObservable(error.message || 'Something went wrong', error.code || 'server/error', MessageTypes.ERROR)),
					take(1)
				)
			),
			switchMap(() => from(this.afa.signOut())
				.pipe(
					map(() => {
						this.router.navigateByUrl(CHANGED_EMAIL_URL);
						return this.showMessageAction('Email changed', 'Email changed', MessageTypes.INFO);
					}),
					catchError(error => this.showMessageObservable(error.message || 'Something went wrong', error.code || 'server/error', MessageTypes.ERROR)),
					take(1)
				)
			)
		)
	);

	reauthenticate$ = createEffect(
		() => this.actions$.pipe(
			ofType(reauthenticate),
			map(action => action.payload),
			switchMap((data: { oldPassword: string, newPassword: string, email: string }) => {
				const credential = auth.EmailAuthProvider.credential(data.email, data.oldPassword);
				return from(this.afa.currentUser)
				.pipe(
					switchMap((currentUser: User) => currentUser.reauthenticateWithCredential(credential)),
					map(() => changePassword({ payload: data.newPassword })),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			})
		)
	);

	changePassword$ = createEffect(
		() => this.actions$.pipe(
			ofType(changePassword),
			map(action => action.payload),
			switchMap(newPassword => from(this.afa.currentUser)
				.pipe(
					switchMap((currentUser: User) => currentUser.updatePassword(newPassword)),
					map(() => this.showMessageAction('Password changed', 'auth/password-changed', MessageTypes.INFO)),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			)
		)
	);

	confirmEmail$ = createEffect(
		() => this.actions$.pipe(
			ofType(confirmEmail),
			concatMap(action => of(action.payload).pipe(
				withLatestFrom(this.store.pipe(select(fromRoot.getAuthState)))
			)),
			switchMap(([token, authState]) => this.http.post(FUNCTIONS_URL + FUNCTIONS_CONFIRM_EMAIL_URL, { token: token })
				.pipe(
					map((response: any) => {
						if (!response.success) {                                                            
							throw new Error('auth/confirm-email-failed', 'Email confirmation failed.');
						}

						if (authState) {
							return this.afa.currentUser;
						}
					}),
					map(() => setLoadingActionName({ payload: 'confirm-email-success'})),
					catchError((error) => of(setLoadingActionName({ payload: 'confirm-email-error'}))),
					take(1)
				)
			)
		)
	);

	resendConfirmationEmail$ = createEffect(
		() => this.actions$.pipe(
			ofType(resendConfirmationEmail),
        	switchMap(() => this.http.post(FUNCTIONS_URL + FUNCTIONS_RESEND_CONFIRMATION_EMAIL_URL, {})
				.pipe(
					map((response: any) => {
						if (response.success) {
							return this.showMessageAction('Confirmation email sent', 'auth/confirmation-email-sent', MessageTypes.INFO);
						}

						throw new Error(response.content.code, response.content.message);
					}),
					catchError(error => this.showMessageObservable(error.message || 'Something went wrong', error.code || 'server/error', MessageTypes.ERROR)),
					take(1)
				)
			)
		)
	);

    private processPasswordData(credential: firebase.auth.UserCredential): UserInterface {
		if (credential.additionalUserInfo.isNewUser) {
            this.store.dispatch(setIsNewUser());
		}
		
        const language = this.storage.get('lang') ? this.storage.get('lang') : 'en';

        return {
            email: credential.user.email,
            email_verified: false,
            uid: credential.user.uid,
            language: language,
            phone_verified: false,
            privacy: {
				email: false,
				address: false,
				phone: false,
				news: false
			},
			notifications: {
				email: false,
				sms: false,
				mobile: false
			}
        };
    }
}