import { createSelector} from '@ngrx/store';
import { LangLocState } from './lang-loc/lang-loc.reducer';
import { UIState } from './ui/ui.reducer';
import { AuthState } from './auth/auth.reducer';
import { SearchState } from './search/search.reducer';

export interface State {
	langLoc: LangLocState;
	ui: UIState,
	auth: AuthState,
	search: SearchState
}

const langLoc = (state: State) => state.langLoc;
export const getLanguage = createSelector(langLoc, (state: LangLocState) => state.language);
export const getLocation = createSelector(langLoc, (state: LangLocState) => state.location);
export const getCountries = createSelector(langLoc, (state: LangLocState) => state.countries);


const ui = (state: State) => state.ui;
export const getLoadingActionName = createSelector(ui, (state: UIState) => state.loadingActionName);
export const getPreviousUrl = createSelector(ui, (state: UIState) => state.prevUrl);
export const getNextUrl = createSelector(ui, (state: UIState) => state.nextUrl);
export const getMessages = createSelector(ui, (state: UIState) => state.messages);
export const getShowHeaderFooter = createSelector(ui, (state: UIState) => state.showHeaderFooter);

const auth = (state: State) => state.auth;
export const getAuthState = createSelector(auth, (state: AuthState) => state.authState);
export const getUser = createSelector(auth, (state: AuthState) => state.user);
export const isAuthLoaded = createSelector(auth, (state: AuthState) => state.loaded);
export const isUserLoaded = createSelector(auth, (state: AuthState) => state.userLoaded);
export const getIsUserNew = createSelector(auth, (state: AuthState) => state.isNewUser);
export const getConfirmation = createSelector(auth, (state: AuthState) => state.confirmation);
export const getAuthHeaderData = createSelector(auth, (state: AuthState) => state.headerData);
export const getCloseAuthUrlStatus = createSelector(auth, (state: AuthState) => state.closeAuthUrl);

const search = (state: State) => state.search;
export const getTransportSearchParams = createSelector(search, (state: SearchState) => state.transportSearchParams)
export const getTransports = createSelector(search, (state: SearchState) => state.transports);
export const getNumberOfTransports = createSelector(search, (state: SearchState) => state.numberOfTransports);
