import { NgModule } from '@angular/core';
import { GuestGuard } from '@guards/guest.guard';
import { AuthGuard } from '@guards/auth.guard';
import { AuthFullGuard } from '@guards/auth-full.guard';

@NgModule({
    imports: [

    ],
    providers: [
        GuestGuard,
        AuthGuard,
        AuthFullGuard
    ]
})
export class GuardsModule {}
