import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { start, setLocation, setLanguage, processLocation, fetchLocationFromServer, getCountriesByRegion, getCountriesByRegionSuccess } from "./lang-loc.actions";
import { map, switchMap, catchError, take } from 'rxjs/operators';
import { StorageService } from '@services/storage.service';
import { Store } from '@ngrx/store';
import { AngularFirestore, QuerySnapshot, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { FUNCTIONS_URL, FUNCTIONS_GET_LOCATION_URL } from '@configs/app.constants';
import { MessageTypes, LocationServerInterface } from '@bts/objects'
import { Error } from '@configs/error';
import { BaseEffects } from '@store/base.effects';

@Injectable()
export class LangLocEffects extends BaseEffects {

	constructor(
		private actions$: Actions,
		private storage: StorageService,
		private store: Store<any>,
		private afs: AngularFirestore,
		private http: HttpClient
	) {
		super();
	}

	start$ = createEffect(
		() => this.actions$.pipe(
			ofType(start),
			map(() => {
				const lang: string = this.storage.get('lang');
				const location: LocationServerInterface = this.storage.get('location');

				if (lang && location) {
					this.store.dispatch(setLocation({ payload: location }));
					this.store.dispatch(setLanguage({ payload: lang }));
				}
				else if (location && !lang) {
					this.store.dispatch(setLocation({ payload: location }));
					this.store.dispatch(processLocation({ payload: location }));
				}
				else {
					this.store.dispatch(fetchLocationFromServer());
				}
			})
		),
		{ dispatch: false}
	);

	processLocation$ = createEffect(
		() => this.actions$.pipe(
			ofType(processLocation),
			map(action => action.payload),
			switchMap((location: LocationServerInterface) => {
				const countryCode = location.country.toUpperCase();

				return this.afs.collection('countries', ref => ref.where('alpha2Code', '==', countryCode)).get()
				.pipe(
					map((countries: QuerySnapshot<any>) => {
						if (!countries.empty) {
							const country = countries.docs[0].data();
							const lang = country.languages[0].iso639_1;
							
							return setLanguage(lang);
						}
					}),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			})
		)
	);
				
	fetchLocationFromServer$ = createEffect(
		() => this.actions$.pipe(
			ofType(fetchLocationFromServer),
			switchMap(() => this.http.post(FUNCTIONS_URL + FUNCTIONS_GET_LOCATION_URL, {})
				.pipe(
					map((response: any) => {
						if (response.success) {
							this.storage.set('location', response.content);
							this.store.dispatch(setLocation({ payload: response.content }));
							this.store.dispatch(processLocation({ payload: response.content }));
						}

						throw new Error(response.content.code, response.content.message);
					}),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			)
		),
		{ dispatch: false }
	);

	getCountriesByRegion$ = createEffect(
		() => this.actions$.pipe(
			ofType(getCountriesByRegion),
			map(action => action.payload),
			switchMap((region: string) => this.afs.collection('countries', ref => ref.where('region', '==', region)).get()
				.pipe(
					map((countries: QuerySnapshot<any>) => {
						if (countries.empty) {                       
							throw new Error('core/countries-not-found', 'Countries not found');
						}

						const data = [];
						countries.forEach((snapshot: QueryDocumentSnapshot<any>) => {
							data.push(snapshot.data());
						});
						
						return getCountriesByRegionSuccess({ payload: data });
					}),
					catchError(error => this.showMessageObservable(error.message, error.code, MessageTypes.ERROR)),
					take(1)
				)
			)
		)
	); 
}