import { NgModule } from "@angular/core";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { LangLocEffects } from './lang-loc/lang-loc.effects';
import { AuthEffects } from './auth/auth.effects';
import { SearchEffects } from './search/search.effects';

import { reducer as LangLocReducer } from './lang-loc/lang-loc.reducer';
import { reducer as UIReducer } from './ui/ui.reducer';
import { reducer as AuthReducer } from './auth/auth.reducer';
import { reducer as SearchReducer } from './search/search.reducer';

@NgModule({
	imports: [
		StoreModule.forRoot(
			{
				langLoc: LangLocReducer,
				ui: UIReducer,
				auth: AuthReducer,
				search: SearchReducer
			},
			{
				runtimeChecks: {
					strictStateImmutability: false,
					strictActionImmutability: false,
				}
			}
		),
		EffectsModule.forRoot([
			LangLocEffects,
			AuthEffects,
			SearchEffects
		]),

		StoreDevtoolsModule.instrument({
			maxAge: 20
		})
	]
})
export class AppStoreModule { }