import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@store/app-store.reducer';
import { start } from '@store/lang-loc/lang-loc.actions';

import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { resetUI, setPrevUrl } from '@store/ui/ui.actions';
import { isAuthenticated } from '@store/auth/auth.actions';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    showHeaderFooter: boolean;

	constructor(
		private store: Store<fromRoot.State>,
        private router: Router,
        private changeDetection: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.store.dispatch(start());
        this.store.dispatch(isAuthenticated());
        
        this.store.pipe(select(fromRoot.getShowHeaderFooter))
        .subscribe((status: boolean) => {
            console.log(status);
            this.showHeaderFooter = status;
            this.changeDetection.detectChanges();
        });

		this.router.events
        .subscribe(res => {            
            if (res instanceof NavigationStart) {
            }  
            
            if (res instanceof NavigationEnd) {
                this.store.dispatch(resetUI());
                if (typeof(window.scrollTo) === "function") {
                    window.scrollTo(0, 0);
                }

				if (res.url.indexOf('auth/') === -1) {
                    this.store.dispatch(setPrevUrl({ payload: res.url }));
                }
            }
        });
	}
}
