import { NgModule } from '@angular/core';

import { LanguageService } from '@services/language.service';
import { StorageService } from '@services/storage.service';
import { MomentModule } from 'ngx-moment';
import { CountryService } from './country.service';
import { SubscriptionService } from './subscription.service';

@NgModule({

})
export class ServicesModule {
    static forRoot() {
        return {
            ngModule: ServicesModule,
            providers: [
                StorageService,
                LanguageService,
                MomentModule,
				CountryService,
				SubscriptionService
            ]
        };
    }
}
