import { Injectable } from "@angular/core";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable()
export class CustomMissingTranslationHandler extends MissingTranslationHandler {
    
    constructor(
        private afs: AngularFirestore
    ) {
        super();
    }

    handle (params: MissingTranslationHandlerParams) {
        // this.afs.collection('translations').doc(params.key).set({ readable: '', currentLang: 'en' });
    }
}