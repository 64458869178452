import { Component } from "@angular/core";
import { SIGN_IN_URL, SIGN_UP_URL } from '@configs/app.constants';
import { Observable, Subscription } from 'rxjs';
import * as fromRoot from '@store/app-store.reducer';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { UserInterface } from '@bts/objects'
import { signOut } from '@store/auth/auth.actions';
import { BaseComponent } from '@modules/base.component';
import { User } from '@objects/user.object';

@Component({
	templateUrl: './header.component.html',
	selector: 'app-header'
})
export class HeaderComponent extends BaseComponent {

	signInUrl: string = SIGN_IN_URL;
	signUpUrl: string = SIGN_UP_URL;
	isUserLoaded: boolean;
	user: User;
	private userLoadedSubscription: Subscription;
	private getUserSubscription: Subscription;

	constructor(
		protected store: Store<fromRoot.State>
	) {
		super(store);
	}

	ngOnInit() {
		super.ngOnInit();
		this.userLoadedSubscription = this.waitForUserLoaded()
		.subscribe(() => this.fetchUser());
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		if (this.userLoadedSubscription) {
			this.userLoadedSubscription.unsubscribe();
		}
		if (this.getUserSubscription) {
			this.getUserSubscription.unsubscribe();
		}
	}

	signOut(): void {
		this.store.dispatch(signOut());
	}

	showLoadNav(): boolean {
		return !this.showAuthItems() && !this.showNonAuthItems();
	}

	showAuthItems(): boolean {
		return this.isUserLoaded && this.user !== null;
	}

	showNonAuthItems(): boolean {
		return this.isUserLoaded && this.user === null;
	}

	private waitForUserLoaded(): Observable<boolean> {
        return this.store.select(fromRoot.isUserLoaded)
            .pipe(
                filter(loaded => loaded)
            );
    }

    private fetchUser(): void {
		this.getUserSubscription = this.store.select(fromRoot.getUser)
		.subscribe((user: UserInterface) => {       
			this.user = user ? new User(user) : null;
			this.isUserLoaded = true;
        });
	}
}