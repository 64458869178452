import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@services/language.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {

    constructor(
        private language: LanguageService
    ) {}

    ngOnInit() {

    }

    setLanguage(language: string): void {
        this.language.setLanguage(language, true);
    }

    getLanguage(): string {
        return this.language.getLanguage()['full'];
    }
}
