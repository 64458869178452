import { Injectable } from "@angular/core";
import { BaseEffects } from '@store/base.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { setSearchParams, searchTransportsSuccess } from './search.actions';
import { withLatestFrom, switchMap, map, take, catchError, concatMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@store/app-store.reducer';
import { FUNCTIONS_URL, FUNCTIONS_SEARCH_TRANSPORT_URL } from '@configs/app.constants';
import { MessageTypes } from '@bts/objects';
import { of } from 'rxjs';
import { Error } from '@configs/error';
import { StorageService } from '@services/storage.service';

@Injectable()
export class SearchEffects extends BaseEffects {

    constructor(
		private actions$: Actions,
		private http: HttpClient,
		private store: Store<fromRoot.State>,
		private storage: StorageService
	) {
		super();
    }
    
    searchTransports$ = createEffect(
		() => this.actions$.pipe(
			ofType(setSearchParams),
			concatMap(action => of(action).pipe(
				withLatestFrom(this.store.pipe(select(fromRoot.getTransportSearchParams)))
			)),
			switchMap(([_, params]) => {
				this.storage.set('search-params', params);
				return this.http.post(FUNCTIONS_URL + FUNCTIONS_SEARCH_TRANSPORT_URL, params)
					.pipe(
						map((response: any) => {
							if (response.success) {
								return searchTransportsSuccess({ payload: {
									transports: response.content.transports,
									numberOfTransports: response.content.numberOfTransports
								}});
							}

							throw new Error(response.content.code, response.content.message);
						}),
						catchError(error => this.showMessageObservable(error.message || 'Something went wrong', error.code || 'server/error', MessageTypes.ERROR)),
						take(1)
					)
			})	
		)
	);
}