import { createAction, props } from '@ngrx/store';
import { MessageInterface } from '@bts/objects'

export const setLoadingActionName = createAction('[UI] Set Loading action name', props<{ payload: string }>());
export const resetLoadingActionName = createAction('[UI] Reset Loading action name');
export const showMessage = createAction('[UI] Show Message', props<{ payload: MessageInterface }>());
export const removeMessage = createAction('[UI] Remove Message', props<{ payload: string }>());
export const setNextUrl = createAction('[UI] Set next url', props<{ payload: string }>());
export const setPrevUrl = createAction('[UI] Set prev url', props<{ payload: string }>());
export const resetUI = createAction('[UI] Reset UI');
export const showHeaderFooter = createAction('[UI] Show header and footer part');
export const hideHeaderFooter = createAction('[UI] Hide header and footer part');