import { TransportSearchParamsInterface, TransportInterface } from '@bts/objects'
import { createReducer, on, Action } from '@ngrx/store';
import { setSearchParams, searchTransportsSuccess } from './search.actions';
import { Transport } from '@objects/transport.object';

export interface SearchState {
    transportSearchParams: TransportSearchParamsInterface,
    transports: Transport[],
    numberOfTransports: number
}

export const initialState: SearchState = {
    transports: null,
    numberOfTransports: null,
	transportSearchParams: null
};

const searchReducer = createReducer(
	initialState,
    on(setSearchParams, (state, action) => ({ ...state, transportSearchParams: action.payload })),
    on(searchTransportsSuccess, (state, action) => {
        const transports = action.payload.transports.map((transport: TransportInterface) => new Transport(transport));
        return { ...state, transports: transports, numberOfTransports: action.payload.numberOfTransports };
    })
);

export function reducer(state: SearchState | undefined, action: Action) {
	return searchReducer(state, action);
}