import { createReducer, Action, on } from '@ngrx/store';
import { setLoadingActionName, resetLoadingActionName, setNextUrl, setPrevUrl, showMessage, 
	resetUI, removeMessage, showHeaderFooter, hideHeaderFooter } from './ui.actions';
import { MessageInterface } from '@bts/objects'
import * as uuid from 'uuid';

export interface UIState {
	loadingActionName: string;
	prevUrl: string;
	nextUrl: string;
	messages: MessageInterface[];
	showHeaderFooter: boolean;
}

export const initialState: UIState = {
	loadingActionName: null,
	prevUrl: '/',
	nextUrl: '/',
	messages: [],
	showHeaderFooter: true
};

const uiReducer = createReducer(
	initialState,
	on(setLoadingActionName, (state, action) => ({ ...state, loadingActionName: action.payload })),
	on(resetLoadingActionName, (state) => ({ ...state, isLoading: null })),
	on(setNextUrl, (state, action) => ({ ...state, nextUrl: action.payload })),
	on(setPrevUrl, (state, action) => ({ ...state, prevUrl: action.payload })),
	on(resetUI, (state) => ({ ...state, loadingActionName: null, messages: []})),
	on(showMessage, (state, action ) => {
		const message: MessageInterface = action.payload;
		message.uuid = uuid.v4();

		return {
			...state,
			loadingActionName: null,
			messages: [
				...state.messages,
				message
			]
		};
	}),
	on(removeMessage, (state, action) => {
		const messages = state.messages.filter((message: MessageInterface) => {
			return message.uuid !== action.payload;
		});
		return {
			...state,
			messages: messages
		};
	}),
	on(showHeaderFooter, (state) => ({ ...state, showHeaderFooter: true })),
	on(hideHeaderFooter, (state) => ({ ...state, showHeaderFooter: false })),
);

export function reducer(state: UIState | undefined, action: Action) {
	return uiReducer(state, action);
}