import { UserInterface, PhoneInterface, NotificationsInterface, PrivacyInterface } from '@bts/objects'
import { NO_AVATAR_IMAGE } from '@configs/app.constants';
import { BaseObject } from './base.object';


export class User extends BaseObject{
	
    protected instance: UserInterface;

    constructor(data: UserInterface) {
		super();
        this.instance = data;
    }

    get avatar(): string {
        return this.instance.avatar ? this.instance.avatar : NO_AVATAR_IMAGE;
    }

    get firstName(): string {
        if (this.instance.first_name) {
            return this.instance.first_name;
        }

        return this.getFirstNameFromEmail();
    }

    set firstName(value: string) {
        this.instance.first_name = value;
    }

    get lastName(): string {
        if (this.instance.last_name) {
            return this.instance.last_name;
        }

        return this.getLastNameFromEmail();
    }

    set lastName(value: string) {
        this.instance.last_name = value;
    }

    get privacy(): PrivacyInterface{
        return this.instance.privacy || null;
    }

    set privacy(privacy: PrivacyInterface) {
        this.instance.privacy = privacy;
    }

    get notifications(): NotificationsInterface{
        return this.instance.notifications || null;
    }

    set notifications(notifications: NotificationsInterface) {
        this.instance.notifications = notifications;
    }

    get phone(): PhoneInterface{
        return this.instance.phone || null;
    }

    set phone(phone: PhoneInterface) {
        this.instance.phone = phone;
    }

    get email(): string{
        return this.instance.email || null;
    }

    set email(email: string) {
        this.instance.email = email;
    }

    get language(): string{
        return this.instance.email || null;
    }

    set language(language: string) {
        this.instance.language = language;
	}
	
	get displayName(): string {
        if (this.instance.first_name && this.instance.last_name) {
            return this.instance.first_name + ' ' + this.instance.last_name;
        }

        if (this.instance.first_name) {
            return this.instance.first_name;
        }

		if (this.instance.last_name) {
            return this.instance.last_name;
        }

        return this.getFromEmail();
	}
	
	get linkedCompanies(): string[] {
		return this.instance.linked_companies || [];
	}

	set linkedCompanies(linkedCompanies: string[]) {
		this.instance.linked_companies = linkedCompanies;
	}

	get linkedCompaniesRoles(): { [id: string]: string } {
		return this.instance.linked_companies_roles || {};
	}

	set linkedCompaniesRoles(linkedCompaniesRoles: { [id: string]: string }) {
		this.instance.linked_companies_roles = linkedCompaniesRoles;
	}

    private getFromEmail(): string {
        // get normal name from email
        let username = this.instance.email.replace(/@.*/,"");
        username = username.replace(/[^A-Z0-9]+/ig, '###');
        const parts = username.split('###');
        if (parts.length < 2) {
            return username.charAt(0).toUpperCase() + username.slice(1);
        }

        const names: string[] = [];
        let i = 0;
        parts.forEach((name: string) => {
            if (i === 0) {
                names.push(name.charAt(0).toUpperCase() + name.slice(1));
            }
            else {
                names.push(name.charAt(0).toUpperCase() + name.slice(1));
            }

            i++;
        });

        return names.join(' ');
    }

    private getFirstNameFromEmail(): string {
        // get normal name from email
        let username = this.instance.email.replace(/@.*/,"");
        username = username.replace(/[^A-Z0-9]+/ig, '###');
        const parts = username.split('###');

        return parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase();
    }

    private getLastNameFromEmail(): string {
        // get normal name from email
        let username = this.instance.email.replace(/@.*/,"");
        username = username.replace(/[^A-Z0-9]+/ig, '###');
        const parts = username.split('###');
        if (parts.length < 2) {
            return null;
        }

        const names: string[] = [];
        let i = 0;
        parts.forEach((name: string) => {
            if (i > 0) {
                names.push(name.charAt(0).toUpperCase() + name.slice(1).toLowerCase());
            }

            i++;
        });

        return names.join(' ');
    }
}