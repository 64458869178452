import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { MessageTypes } from '@bts/objects'
import { showMessage } from './ui/ui.actions';

export abstract class BaseEffects {
    
    protected showMessageObservable(readable: string, code: string, type: MessageTypes): Observable<Action> {
        return of(this.showMessageAction(readable, code, type));
    }

    protected showMessageAction(readable: string, code: string, type: MessageTypes): Action {
        return showMessage({
            payload: {
                readable: readable,
                code: code,
                messageType: type
            }
        });
	}
}