import { Store, select } from "@ngrx/store";
import * as fromRoot from '@store/app-store.reducer';
import { setLoadingActionName } from '@store/ui/ui.actions';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractControl } from '@angular/forms';

export abstract class BaseComponent implements OnInit, OnDestroy {

	loaderKeyWord: string;
	loadingActionName: string;
	protected formSubmitted: boolean = false;
	private getLoadingActionSubsciption: Subscription;

	constructor(
		protected store: Store<fromRoot.State>
	) { }

	ngOnInit() {
		this.getLoadingAction();
	}

	ngOnDestroy() {
		if (this.getLoadingActionSubsciption) {
			this.getLoadingActionSubsciption.unsubscribe();
		}
	}

	dispatchSetLoadingActionName(loadingActionName: string): void {
		this.store.dispatch(setLoadingActionName({ payload: loadingActionName }));
	}


	showLoader(): boolean {
		return this.loadingActionName === this.loaderKeyWord;
	}

	isFieldInvalid(property: AbstractControl): boolean {
		if (property.invalid && this.formSubmitted) {
			return true;
		}

		return false;
	}
	
	private getLoadingAction(): void {
		this.getLoadingActionSubsciption = this.store.pipe(select(fromRoot.getLoadingActionName))
			.subscribe((action: string) => this.loadingActionName = action);
	}
}