import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { switchMap, filter, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as fromRoot from '@store/app-store.reducer';
import { User } from 'firebase';
import { setNextUrl } from '@store/ui/ui.actions';
import { SIGN_IN_URL, CONFIRM_PHONE_NUMBER_URL, CONFIRM_EMAIL_URL } from '@configs/app.constants';

@Injectable()
export class AuthFullGuard implements CanActivate {

    constructor(
        private router: Router,
        private store: Store<fromRoot.State>
    ) { }

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const url: string = state.url;

        if (url.indexOf('auth/') === -1) {
            this.store.dispatch(setNextUrl({ payload: url }));
        }

        return this.waitForUserToLoad().pipe(
            switchMap(() => this.canShow())
        );
    }

    private waitForUserToLoad(): Observable<boolean> {
        return this.store.select(fromRoot.isAuthLoaded)
            .pipe(
                filter(loaded => loaded),
                take(1)
            );
    }

    private canShow(): Observable<boolean> {
        return this.store.select(fromRoot.getAuthState).pipe(
            switchMap((user: User) => {
                if (!user) {
                    this.router.navigateByUrl(SIGN_IN_URL);
                    return of(false);
                } 
                else if (!user.emailVerified) {                    
                    this.router.navigateByUrl(CONFIRM_EMAIL_URL);
                    return of(false);
                }
                else if (!user.phoneNumber) {                    
                    this.router.navigateByUrl(CONFIRM_PHONE_NUMBER_URL);
                    return of(false);
                }
                else {
                    return of(true);
                }
            })
        );
    }
}
