import { AddressDataInterface, CarrierInterface } from '@bts/objects'

export abstract class BaseObject {
	protected instance;

	get object(): any {
        return this.instance;
    }

    get uid(): string {
        return this.instance.uid;
	}

	get formattedAddress(): string {
        if (this.instance.address && this.instance.address.formatted) {
            return this.instance.address.formatted;
        }

        return null;
    }

	get address(): AddressDataInterface{
        return this.instance.address || null;
    }

    set address(address: AddressDataInterface) {
        this.instance.address = address;
    }

    get placeId(): string {
		if (!this.instance || !this.instance.address) {
			return null;
		}

        return this.instance.address.placeId || null;
    }

    get latitude(): number {
		if (!this.instance || !this.instance.address) {
			return null;
		}
        return this.instance.address.latitude || null;
    }

    get longitude(): number {
		if (!this.instance || !this.instance.address) {
			return null;
		}
        return this.instance.address.longitude || null;
	}
	
	get carrier(): CarrierInterface {
		return this.instance.carrier || null;
	}

	get countries(): string[] {
		if (!this.instance.carrier || !this.instance.carrier.countries) {
			return [];
		}

		return this.instance.carrier.countries;
	}

	get trucks(): string[] {
		if (!this.instance.carrier || !this.instance.carrier.trucks) {
			return [];
		}

		return this.instance.carrier.trucks;
	}

	get tempRegimes(): string[] {
		if (!this.instance.carrier || !this.instance.carrier.temperature_regimes) {
			return [];
		}

		return this.instance.carrier.temperature_regimes;
	}

	get exchangeablePallets(): boolean {
		if (!this.instance.carrier || !this.instance.carrier.exchangeable_pallets) {
			return false;
		}

		return this.instance.carrier.exchangeable_pallets;
	}

}