import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from '@modules/core/messages/messages.component';

@NgModule({
    imports: [
		CommonModule
    ],
    declarations: [
        MessagesComponent
    ],
    exports: [
        MessagesComponent
    ],
    providers: [
    ]
})
export class MessagesModule {}
