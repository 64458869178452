import { Injectable } from "@angular/core";
import { EN } from "assets/helpers/countries/en";
import { SL } from "assets/helpers/countries/sl";

@Injectable()
export class CountryService {

    private countries;

    constructor() {}

	getCountryByCode(code: string, language: string): string {
        if (!this.countries) {
            switch (language) {
                case 'sl':
                    this.countries = SL;
                    break;
                
                default:
                    this.countries = EN;
                    break;
            }
        }

        return this.countries[code];
    }
}