import { Injectable } from '@angular/core';
import { CookieStorage } from 'cookie-storage';

@Injectable()
export class StorageService {

    private storage = null;

    constructor() {
        this.storage = this.getTokenStorage();
    }

    set(name, data): void {
        this.storage.setItem(name, JSON.stringify(data));
    }

    get(name): any {
        let val: string = null;

        try {
            val = JSON.parse(this.storage.getItem(name));
        } catch (e) {
            val = null;
        }

        return val;
    }

    remove(key: string) {
        return this.storage.removeItem(key);
    }

    /**
     * Returns what type of storage we can use.
     * In safari private browsing, local storage cannot be used!
     */
    private getTokenStorage(): any {
        let localStorageAvailable = true;

        // @TODO: If not in browser - what do we do?
        if (!window.localStorage) {
            return {
                setItem: function () { },
                getItem: function () { },
                removeItem: function () { }
            };
        }

        // Try localStorage
        try {
            localStorage.setItem('for-test', 'test');
            localStorage.removeItem('for-test');
        } catch (err) {
            localStorageAvailable = false;
        }

        return localStorageAvailable ?
            localStorage :
            new CookieStorage({ path: '/', expires: this.yearsAhead(5) });
    }

    /**
     * Returns date that is N years ahead of today
     */
    private yearsAhead(nbYears: number): Date {
        const date = new Date();
        date.setFullYear(date.getFullYear() + nbYears);
        return date;
    }
}
