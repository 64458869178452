import { BaseObject } from './base.object';
import { TransportInterface, AddressDataInterface } from '@bts/objects'
import * as moment from 'moment';

export class Transport extends BaseObject {

	protected instance: TransportInterface;

	constructor(data: TransportInterface) {
		super();
		this.instance = data;
	}

	get pickupTitle(): string {
		return this.instance.pickup_location.components.city + ', ' + this.instance.pickup_location.components.country;
	}

	get destinationTitle(): string {
		return this.instance.destination_location.components.city + ', ' + this.instance.destination_location.components.country;
	}

	get date(): string {
		try {
            return moment(this.instance.date.toDate()).format('ddd, DD. MMM');
        } catch (error) {
            return moment(new Date(this.object.date['_seconds'] * 1000)).format('ddd, DD. MMM');
        }
	}


	get year(): string {
		try {
            return moment(this.instance.date.toDate()).format('YYYY');
        } catch (error) {
            return moment(new Date(this.object.date['_seconds'] * 1000)).format('YYYY');
        }
	}

	get time(): string {
        const date = new Date();
        date.setHours(this.object.time.hours);
        date.setMinutes(this.object.time.minutes);
        return moment(date).format('HH:mm');
	}
	
	get destination(): AddressDataInterface {
		return this.instance.destination_location;
	}

	get pickup(): AddressDataInterface {
		return this.instance.pickup_location;
	}

	get pickupObject(): {lat: number, lng: number} {
        return {
            lat: this.pickup.latitude,
            lng: this.pickup.longitude
        };
    }

    get destinationObject(): {lat: number, lng: number} {
        return {
            lat: this.destination.latitude,
            lng: this.destination.longitude
        };
    }

	get numberOfPallets(): number {
		return this.instance.number_of_pallets;
	}

	get truckType(): string {
		return this.instance.truck_type;
	}

	get tempRegime(): string {
		return this.instance.temperature_regime;
	}

	get radius(): number {
		return this.instance.pickup_destination_radius;
	}
}