import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { User } from 'firebase';

import * as fromRoot from '@store/app-store.reducer';
import { setNextUrl } from '@store/ui/ui.actions';
import { SIGN_IN_URL } from '@configs/app.constants';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private store: Store<fromRoot.State>,
        private router: Router
    ) { }

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const url: string = state.url;
        if (url.indexOf('auth/') === -1) {
            this.store.dispatch(setNextUrl({ payload: url }));
        }

        return this.waitForAuthToLoad().pipe(
            switchMap(() => this.canShow())
        );
    }

    private waitForAuthToLoad(): Observable<boolean> {
        return this.store.select(fromRoot.isAuthLoaded)
            .pipe(
                filter(loaded => loaded),
                take(1)
            );
    }

    private canShow(): Observable<boolean> {
        return this.store.select(fromRoot.getAuthState).pipe(
            switchMap((user: User) => {
                if (!user) {
                    this.router.navigateByUrl(SIGN_IN_URL);
                    return of(false);
                } else {
                    return of(true);
                }
            })
        );
    }
}
