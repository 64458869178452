import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, from} from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

    constructor (
        private afa: AngularFireAuth
    ) { }

    // intercept request and add token
    intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.getToken())
            .pipe(mergeMap(token => {
                if (token) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                }
                return next.handle(request)
                .pipe(
                    tap(event => {
                        if (event instanceof HttpResponse) {}
                    })
                );
            })
        );
    }

    private getToken(): Observable<string> {
        return this.afa.idToken;
    }
}
