import { User } from 'firebase';
import { UserInterface, AuthHeaderDataInterface } from '@bts/objects'
import { createReducer, Action, on } from '@ngrx/store';
import { authenticated, fetchUserSuccess, notAuthenticated, setIsNewUser, 
	setIsNotNewUser, storeUserSuccess, signOutSuccess, setPhoneConfirmationResult, 
	setHeaderData, setCloseAuthUrlStatus } from './auth.actions';

export interface AuthState {
	authState: User;
	user: UserInterface;
	loaded: boolean;
	userLoaded: boolean;
	confirmation: firebase.auth.ConfirmationResult;
	isNewUser: boolean;
	headerData: AuthHeaderDataInterface;
	closeAuthUrl: boolean;
}

const initialState: AuthState = {
    authState: null,
    user: null,
    loaded: false,
    userLoaded: false,
	confirmation: null,
	isNewUser: false,
	headerData: null,
	closeAuthUrl: false
};

const authReducer = createReducer(
	initialState,
	on(authenticated, (state, action) => ({ ...state, authState: action.payload, loaded: true })),
	on(notAuthenticated, (state) => ({ ...state, authState: null, loaded: true, user: null, userLoaded: true })),
	on(fetchUserSuccess, (state, action) => ({ ...state, user: action.payload, userLoaded: true })),
	on(setIsNewUser, (state) => ({ ...state, isNewUser: true })),
	on(setIsNotNewUser, (state) => ({ ...state, isNewUser: false })),
	on(storeUserSuccess, (state, action) => ({ ...state, user: action.payload, userLoaded: true })),
	on(signOutSuccess, (state) => ({ ...state, user: null, authState: null, isNewUser: false })),
	on(setPhoneConfirmationResult, (state, action) => ({ ...state, confirmation: action.payload })),
	on(setHeaderData, (state, action) => ({ ...state, headerData: action.payload })),
	on(setCloseAuthUrlStatus, (state, action) => ({ ...state, closeAuthUrl: action.payload }))
);

export function reducer(state: AuthState | undefined, action: Action) {
	return authReducer(state, action);
}