import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomMissingTranslationHandler } from '@configs/custom-missing-translation-handler';
import { CustomTraslateLoader } from '@configs/custom-translate-loader';
import { environment } from '@env';
import { ServicesModule } from '@services/services.module';
import { AppStoreModule } from '@store/app-store.module';
import { GuardsModule } from '@guards/guards.module';
import { MessagesModule } from '@modules/core/messages/messages.module';
import { AppInterceptor } from '@configs/app.interceptor';
import { HeaderModule } from '@modules/core/header/header.module';
import { FooterModule } from '@modules/core/footer/footer.module';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		CommonModule,
		AppRoutingModule,
		HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTraslateLoader
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler }
		}),
		
		AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
		AngularFireStorageModule,

		AppStoreModule,
		GuardsModule,
		
		ServicesModule.forRoot(),
		
		MessagesModule,
		HeaderModule,
		FooterModule
	],
	providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppInterceptor,
            multi: true
        }
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
