export const FUNCTIONS_URL = 'https://us-central1-bts-app-5b8e0.cloudfunctions.net/';
export const FUNCTIONS_GET_LOCATION_URL = 'getLocation';
export const FUNCTIONS_CONFIRM_PHONE_URL = 'phoneConfirm';
export const FUNCTIONS_CONFIRM_EMAIL_URL = 'emailConfirm';
export const FUNCTIONS_RESEND_CONFIRMATION_EMAIL_URL = 'confirmationEmail';
export const FUNCTIONS_SEND_PASSWORD_RESET_URL = 'sendResetPassword';
export const FUNCTIONS_RESET_PASSWORD_URL = 'resetPassword';
export const FUNCTIONS_CHANGE_EMAIL_URL = 'changeEmail';
export const FUNCTIONS_SEARCH_USER_URL = 'searchUsers';
export const FUNCTIONS_SEARCH_TRANSPORT_URL = 'searchTransports';

export const SIGN_IN_URL = '/auth/sign-in';
export const SIGN_UP_URL = '/auth/sign-up';
export const CONFIRM_PHONE_NUMBER_URL = '/auth/confirm-mobile-number';
export const PROVIDE_PHONE_NUMBER_URL = '/auth/provide-mobile-number';
export const CONFIRM_EMAIL_URL = '/auth/confirm-email';
export const FORGOTTEN_PASSWORD_URL = '/auth/forgotten-password';
export const RESET_PASSWORD_URL = '/auth/reset-password';
export const CHANGED_EMAIL_URL = '/auth/changed-email';

export const DEFAULT_NO_IMAGE = '/assets/images/only-logo-blue.svg';
export const NO_AVATAR_IMAGE = '/assets/images/user.svg';

export const LANGUAGES = [
    {
        short: 'sl',
        full: 'Slovenščina'
    },
    {
        short: 'en',
        full: 'English'
    }
];
export const DEFAULT_LANGUAGE = 'en';
export const TRUCK_OPTIONS = ['Cerada', 'Frigo', 'Cisterna', 'Avtovlačilec', 'Jumbo', 'Kombi', 'Mega'];
export const TEMP_REGIMES = [
    '-20/-12',
    '-12/0',
    '0/4',
	'0/8',
	'NO_REGIME'
];