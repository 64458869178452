import { LocationServerInterface } from '@bts/objects'
import { createReducer, on, Action } from '@ngrx/store';
import { setLocation, setLanguage, getCountriesByRegionSuccess } from './lang-loc.actions';

export interface LangLocState {
	language: string,
	location: LocationServerInterface,
	countries: any;
}

export const initialState: LangLocState = {
	language: null,
	location: null,
	countries: null
};

const langLocReducer = createReducer(
	initialState,
	on(setLocation, (state, action) => ({ ...state, location: action.payload })),
	on(setLanguage, (state, action) => ({ ...state, language: action.payload })),
	on(getCountriesByRegionSuccess, (state, action) => ({ ...state, countries: action.payload }))
)

export function reducer(state: LangLocState | undefined, action: Action) {
	return langLocReducer(state, action);
}