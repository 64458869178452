import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { AuthFullGuard } from '@guards/auth-full.guard';

const routes: Routes = [
	{
		path: 'transports',
		loadChildren: () => import('@modules/transports/transports.module').then(m => m.TransportsModule),
	},
	{
		path: 'company',
		loadChildren: () => import('@modules/company/company.module').then(m => m.CompanyModule),
		canActivate: [ AuthFullGuard ]
	},
	{
		path: 'companies',
		loadChildren: () => import('@modules/companies/companies.module').then(m => m.CompaniesModule),
		canActivate: [ AuthFullGuard ]
	},
	{
		path: 'user-settings',
		loadChildren: () => import('@modules/user-settings/user-settings.module').then(m => m.UserSettingsModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: 'auth',
		loadChildren: () => import('@modules/auth/auth.module').then(m => m.AuthModule)
	},
	{
		path: '',
		loadChildren: () => import('@modules/home/home.module').then(m => m.HomeModule),
		
	},
	{
		path: '**',
		redirectTo: '',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		preloadingStrategy: PreloadAllModules,
		enableTracing: false
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
